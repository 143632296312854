<template>
<h1>Demo App Page</h1>
</template>

<script>
export default {
  name: "Demo"
}
</script>

<style scoped>

</style>